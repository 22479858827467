import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import './partnership-carousel.scss'
import { Trans, useTranslation } from 'react-i18next'
import { FaQuoteLeft } from 'react-icons/fa6'
import { FaQuoteRight } from 'react-icons/fa6'

function PartnershipCarousel() {
  const { t } = useTranslation('partnership')

  return (
    <section className="partnership-carousel section-carousel">
      <Carousel
        animationHandler="fade"
        infiniteLoop
        showStatus={false}
        showThumbs={false}
        swipeable={false}
        emulateTouch={false}
      >
        <div className="partnership-carousel-children">
          <div className="legend">
            <div className="img-container">
              <a href="https://www.fondation.universite-paris-saclay.fr/les-entreprises-partenaires" target="blank">
                <img src="/images/fondation.jpg" alt="fondation" />
              </a>
              <img src="/images/saclay.jpg" alt="Saclay" />
            </div>
            <div className="text-container">
              <h1 className="main-title">
                {t('Auxasphere is the new collaborative platform')}
              </h1>
              <h2 className="sub-title">{t('About the foundation')}</h2>
              <i>
                <div className="desc-container">
                  <p className="desc citation-saclay">
                    <FaQuoteLeft />
                    {t(
                      'The Paris-Saclay University Foundation catalyzes the research, training, innovation and sustainable development missions of Paris-Saclay University through major patronage in line with its international ambitions and the major challenges of the 21st century, such as inclusive education, climate change, augmented health for all and renewable energies.',
                    )}
                  </p>
                  <p className="desc citation-saclay mb-1em">
                    {t(
                      "As the originator of the revolutionary 'positive impact innovation reactor', it encourages the emergence of world firsts and accelerates them around unique, multi-disciplinary and multi-business ecosystems, bringing together the best researchers, start-ups, industrial partners, investors and sponsors, local and regional players, users and sources of targeted data, while respecting the fundamental values of ethics, privacy and trust in science for the good of people and the planet.",
                    )}
                    <FaQuoteRight />
                  </p>
                  <p className="bold">
                    {t('Patrick Duvaut - President - Fondation Université Paris-Saclay')}
                  </p>
                </div>
              </i>
              <a
                className="learn-more"
                href="https://www.fondation.universite-paris-saclay.fr/les-entreprises-partenaires"
                target="blank"
              >
                {t('Learn more')}
              </a>
            </div>
          </div>
        </div>
        <div className="partnership-carousel-children">
          <div className="legend">
            <div className="img-container">
              <a href="https://www.fondation.universite-paris-saclay.fr/les-entreprises-partenaires" target="blank">
                <img src="/images/fondation.jpg" alt="fondation" />
              </a>
              <img src="/images/saclay.jpg" alt="Saclay" />
            </div>
            <div className="text-container">
              <h2 className="sub-title">{t('Augmented healthcare programmes')}</h2>
              <div className="desc-container">
                <p className="desc">
                  {t("We are honoured to be taking part in the launch of the ")}
                  <b><a href="https://www.fondation.universite-paris-saclay.fr/chaire-innovation-life-care-medicine" target="blank">{t("Life Care Medicine Institute")}</a></b>
                  {t(", dedicated to precision medicine known as the 5Ps (Preventive, Predictive, Personalised, Participative, Probative).")}
                </p>
                <div className="site-container">
                  <img src="/images/lcm.png" alt="lcm" />
                </div>
                <p className="desc no-margin">
                  <Trans
                    ns="partnership"
                    i18nKey="PREVENTION"
                    components={{ bold: <strong /> }}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="partnership-carousel-children">
          <div className="legend">
            <div className="img-container">
              <a href="https://www.fondation.universite-paris-saclay.fr/les-entreprises-partenaires" target="blank">
                <img src="/images/fondation.jpg" alt="fondation" />
              </a>
              <img src="/images/saclay.jpg" alt="Saclay" />
            </div>
            <div className="text-container">
              <h2 className="sub-title">{t('Augmented healthcare programmes')}</h2>
              <div className="desc-container">
                <p className="desc mb-1em">
                  <Trans
                    ns="partnership"
                    i18nKey="SYSTEM"
                    components={{ bold: <strong /> }}
                  />
                </p>
                <div className="obese-container">
                  <img className="obese-img" src="/images/obese.png" />
                </div>

                <p className="desc mb-1em">
                  <Trans
                    ns="partnership"
                    i18nKey="MONITORING"
                    components={{ bold: <strong /> }}
                  />
                </p>
                <p className="desc citation">
                  <FaQuoteLeft />
                  {t(
                    'Everyone becomes a proactive player in a new model of the healthcare chain.',
                  )}
                  <FaQuoteRight />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="partnership-carousel-children">
          <div className="legend">
            <div className="img-container">
              <a href="https://www.fondation.universite-paris-saclay.fr/les-entreprises-partenaires" target="blank">
                <img src="/images/fondation.jpg" alt="fondation" />
              </a>
              <img src="/images/saclay.jpg" alt="Saclay" />
            </div>
            <div className="text-container">
              <h2 className="sub-title">{t('Augmented healthcare programmes')}</h2>
              <div className="desc-container">
                <p className="desc mb-1em">
                  <Trans
                    ns="partnership"
                    i18nKey="CURATIVE"
                    components={{ bold: <strong /> }}
                  />
                </p>
                <p className="desc mb-1em">
                  <Trans
                    ns="partnership"
                    i18nKey="AI"
                    components={{ bold: <strong /> }}
                  />
                </p>
                <p className="desc mb-1em">
                  <Trans
                    ns="partnership"
                    i18nKey="BIG_DATA"
                    components={{ bold: <strong /> }}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="partnership-carousel-children">
          <div className="legend">
            <div className="img-container">
              <a href="https://www.fondation.universite-paris-saclay.fr/les-entreprises-partenaires" target="blank">
                <img src="/images/fondation.jpg" alt="fondation" />
              </a>
              <img src="/images/saclay.jpg" alt="Saclay" />
            </div>
            <div className="text-container">
              <h2 className="sub-title">{t('Augmented healthcare programmes')}</h2>
              <div className="desc-container">
                <p className="desc">
                  <Trans
                    ns="partnership"
                    i18nKey="LIFE_CARE_INSTITUTE"
                    components={{ bold: <strong /> }}
                  />
                </p>
                <div className="site-container">
                  <img src="/images/circamed_new.png" alt="lcm" />
                </div>
                <p className="desc">
                  {t("The ")}
                  <b><a href="https://www.fondation.universite-paris-saclay.fr/chaire-innovation-circamed" target="blank">{t("Circamed programme")}</a></b>
                  <Trans
                    ns="partnership"
                    i18nKey="CHRONOTYPES"
                    components={{ bold: <strong /> }}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </Carousel>
    </section>
  )
}

export default PartnershipCarousel
